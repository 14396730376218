/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useMedia } from "react-use"
import InfiniteScroll from "react-infinite-scroller"
import { Button } from "@theme-ui/components"
import queryString from "query-string"

import Column from "../components/column"
import Container from "../components/container"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectCard from "../components/project-card"
import Row from "../components/row"
import Text from "../components/text"
import theme from "../gatsby-plugin-theme-ui/index"
import CTA from "../components/cta"

import { mediaQueryList, renderLineBreak } from "../helpers"

import { useIsClient } from "../hooks/use-is-client"
import { paramsCheck } from "../functions/functions"

let titles = [
  "ADU (Detached Unit)",
  "Single-Family Homes",
  "Multi-Family Homes",
  "Developments",
  "Hospitality",
  "Student Housing",
]
const ProjectGalleryPage = ({ data, location }) => {
  paramsCheck()

  const filterQuery =
    queryString.parse(location.search).projectType ||
    queryString.parse(location.hash.substr(2)).projectType

  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const extraCardsAmount = !isClient || (isClient && mediaQueryMatch) ? 7 : 4
  const fullWidthList = [true, true, true];
  const projects = [...data.projects.orderItems,...data.legacyProjects.orderItems]

  const [filteredProjects, setfilteredProjects] = useState(
    [...data.projects.orderItems,...data.legacyProjects.orderItems]
  )
  const [groupFilteredProjects, setGroupFilteredProjects] = useState([]);
  const [resetHover, setResetHover] = useState(false)

  const [projectFilter, setProjectFilter] = useState({
    adu: false,
    singlefamily: false,
    multifamily: false,
    development: false,
    hospitality: false,
    studenthousing: false,
  })

  const [filterTitle, setFilterTitle] = useState("")

  const [cardsLimit, setCardsLimit] = useState(4)
  useEffect(() => {
    setCardsLimit(!isClient || (isClient && mediaQueryMatch) ? 7 : 4)
  }, [isClient, mediaQueryMatch])

  const renderProjects = () => {
    if (filterQuery) {
      let queryString =
        filterQuery === "student-housing" ? "Student housing" : filterQuery
      let title = titles
        .map(t => {
          var re = new RegExp(queryString, "gi")
          if (t.match(re)) return t
        })
        .filter(t => {
          return t !== undefined
        })
      title.length !== 0 && setFilterTitle(title[0])
    } else {
      setfilteredProjects(
        projects.length > 0
          ? [...new Set(projects)]
          : checkAllFilter()
          ? projects
          : [...data.projects.orderItems,...data.legacyProjects.orderItems]
      )
      setFilterTitle("")
    }
  }

  useEffect(() => {
    if (filterTitle) {
      setProjectFilter({
        ...projectFilter,
        [filterQuery?.replace(/-|\s/g, "")]: true,
      })
    }
  }, [filterTitle])

  const filterEvent = e => {
    setProjectFilter({
      ...projectFilter,
      [e.target.id]: !projectFilter[e.target.id],
    })
  }

  const clearFilter = () => {
    let obj = {}
    for (let key in projectFilter) {
      obj[key] = false
    }
    setProjectFilter({ ...obj })
  }

  useEffect(() => {
    applyFilter()
  }, [projectFilter])

  useEffect(() => {
    clearFilter()
    renderProjects()
  }, [])

  const applyFilter = () => {
    let selectedFilters = []
    for (let key in projectFilter) {
      if (projectFilter[key] === true) {
        selectedFilters.push(key)
      }
    }
    selectedFilters = selectedFilters.filter(t => {
      return t !== "undefined"
    })
    let newfilteredProjects = projects.filter(project => {
      let projectType = project?.projectType?.map(type =>
        type.replace(/-|\s/g, "").toLowerCase()
      )
      if (selectedFilters.some(item => projectType?.includes(item)))
        return project
    })
    if (newfilteredProjects.length !== 0 && selectedFilters.length !== 0) {
      setfilteredProjects(newfilteredProjects)
    } else if (
      selectedFilters.length !== 0 &&
      newfilteredProjects.length == 0
    ) {
      setfilteredProjects(newfilteredProjects)
    } else {
      setfilteredProjects(projects)
    }
  }

  const {
    adu,
    singlefamily,
    multifamily,
    studenthousing,
    development,
    hospitality
  } = projectFilter

  const renderFilter = () => {
    const headerHeight = [145, 125, 72, 60]
    return (
      <div
        sx={{
          position: "relative",
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            backgroundColor: "white",
            height: headerHeight,
            width: "100%",
            transition: theme => `background-color ${theme.transitions}`,
            ...{
              bg: "accent",
              // position: "fixed",
              // top: 0,
              zIndex: 2,
            },
          }}
        >
          <Container>
            <Row
              customStyle={{
                alignItems: "center",
              }}
            >
              <Column
                size={[12, 12, 3, 2, 2, 1]}
                sx={
                  {
                    // alignItems:"center"
                  }
                }
              >
                <Text
                  customStyle={{
                    alignItems: "center",
                    color: "white",
                    display: "flex",
                    opacity: 0,
                    alignItems: "center",
                    pr: [0, 0, theme => theme.spacing.horizontal],
                    pb: "5px",
                    transition: theme => `opacity ${theme.transitions}`,
                    ...{ opacity: 1 },
                  }}
                  // type="h3"
                >
                  Filter by:
                </Text>
              </Column>
              <Column size={[12, 12, 4, 3, 2, 2]}>
                <div
                  className="filter-dropdown"
                  sx={{ ml: ["-5px", "-5px", 0, 0, 0, 0] }}
                >
                  <Button
                    sx={{
                      ...theme.forms.buttonChevron,
                      fontSize: 14,
                      pr: 4,
                      py: "0px",
                      pl: 19,
                      // mt: 26,
                      mx: [0, 0, 3],
                      // ml: ["-15px","-15px",0,0,3],
                      display: "flex",
                      justifyContent: "space-between",
                      width: 190,
                      color: "white",
                      border: theme => `2px solid ${theme.colors.secondary}`,
                      background: theme.colors.secondary,
                      whiteSpace: "nowrap",
                      ":hover": {
                        border: theme => `2px solid ${theme.colors.primary}`,
                        color: "white",
                        background: theme.colors.primary,
                      },
                    }}
                    onMouseOver={() => {
                      setResetHover && setResetHover("")
                    }}
                    // onFocus={() => setResetHover && setResetHover(false)}
                    onClick={() => setResetHover("Size")}
                    id="sizeButton"
                  >
                    Project Type
                  </Button>
                  {resetHover === "Size" && (
                    <ul
                      className="dropdown-list"
                      onMouseLeave={() => setResetHover("")}
                    >
                      {/* <li><Input type="checkbox"/>Demo</li> */}
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          id="adu"
                          checked={adu}
                          value="adu"
                        />
                        <label for="adu"> ADU (Detached Unit)</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          id="singlefamily"
                          checked={singlefamily}
                          value="singlefamily"
                        />
                        <label for="singlefamily">Single-Family</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="multifamily"
                          id="multifamily"
                          checked={multifamily}
                        />
                        <label for="multifamily">Multifamily</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="development"
                          id="development"
                          checked={development}
                        />
                        <label for="development">Development</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="hospitality"
                          id="hospitality"
                          checked={hospitality}
                        />
                        <label for="hospitality">Hospitality</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="studenthousing"
                          id="studenthousing"
                          checked={studenthousing}
                        />
                        <label for="studenthousing">Student Housing</label>
                      </li>

                      <li>
                        <Button
                          sx={{
                            color: "white",
                            fontSize: 3,
                            fontFamily: theme => theme.fonts.body,
                            pl: 25,
                            pr: 20,
                            py: 2,
                            borderRadius: 9999,
                            cursor: "pointer",
                            "&::after": {
                              position: "relative",
                              bottom: "1px",
                            },
                            fontSize: 14,
                            pr: 4,
                            py: 2,
                            pl: 19,
                            mr: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            width: 110,
                            border: theme =>
                              `2px solid ${theme.colors.secondary}`,
                            background: theme.colors.secondary,
                            whiteSpace: "nowrap",
                            fontSize: "14px ",
                            fontWeight: "normal",
                            ":hover": {
                              border: theme =>
                                `2px solid ${theme.colors.primary}`,
                              background: theme.colors.primary,
                            },
                          }}
                          onClick={() => {
                            clearFilter()
                          }}
                        >
                          Clear Filter
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </Column>
            </Row>
          </Container>
        </Flex>
      </div>
    )
  }

  const checkAllFilter = () => {
    const filtersObj = { ...projectFilter }
    const activeFilter = Object.values(filtersObj).filter(ele => ele)
    return activeFilter.length > 0 ? true : false
  }

  const renderViewAllButton = () => {
    return (
      <Flex
        sx={{
          justifyContent: ["flex-start", "flex-start", "flex-end"],
        }}
      >
        <CTA
          link={"/projects"}
          type="secondary"
          linkType="internal"
          onClick={() => clearFilter()}
        >
          View All Projects
        </CTA>
      </Flex>
    )
  }
  const legacyButton = () => {
    return (
      <Flex
        sx={{
          justifyContent: ["flex-start", "flex-start", "center"],
        }}
      >
        <CTA
          link={"/legacy-projects"}
          type="secondary"
          linkType="internal"
          customStyle={{fontSize:"15px"}}
        >
          Legacy Projects
        </CTA>
      </Flex>
    )
  }

  React.useEffect(() => {
    if (filterQuery) {
      clearFilter()
      renderProjects()
    } else {
      renderProjects()
    }
  }, [filterQuery])
  React.useEffect(() => {
    if (filteredProjects) {
      const order = [
          "100% Affordable Housing",
          "Multifamily",
          "Multi-Unit Developments",
          "Hospitality & Student Housing",      
          "Single-Family Homes",
          "Single Family",
          "Development",
          "ADUs"
      ];

       // Create a mapping from project type to its index in the order
        const typePriority = Object.fromEntries(order.map((type, index) => [type, index]));
        const combinedType = "Hospitality & Student Housing";
        const combinedTypes = new Set(["Hospitality", "Student Housing"]);
        const nullIndex = order.length;

        // Initialize subarrays
        const subarrays = Array(order.length + 1).fill(null).map(() => []);

        // Populate subarrays
        filteredProjects.forEach((item) => {
            const index = combinedTypes.has(item.projectTytpe) ? typePriority[combinedType] : (item.projectTytpe === null ? nullIndex : typePriority[item.projectTytpe]);
            if (index !== undefined) subarrays[index].push(item);
        });

        // Build final result, excluding empty subarrays
        const finalArray = subarrays
            .map((subarray, index) => subarray.length ? { type: order[index] || '', projects: subarray } : null)
            .filter(Boolean);
        setGroupFilteredProjects(finalArray);
    }
}, [filteredProjects]);

  return (
    <Page data={data.contentfulPage}>
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            {
              <Hero
                background={{
                  type: "image",
                  source: {
                    mobileImage: data.hero.mobileImage,
                    desktopImage: data.hero.desktopImage,
                  },
                }}
                body={
                  data.hero.description ? JSON.parse(data.hero.description.description).content[0]
                    .content[0].value: ""
                }
                title={data.hero.title}
                type="secondary"
                textWidth={576}
                verticalAlign="center"
              />
            }
          </Column>
        </Row>
        {/* {filterTitle ? null : renderFilter()} */}
      </Container>
      <Container>
        {filterTitle ? (
          <div>
            <div className="modelsrow"></div>
            <Row customStyle={{ mb: 3 }}>
              <Column size={[12, 12, 8]} customStyle={{ mt: 4 }}>
                <Text type="h2">
                  {filteredProjects.length > 0
                    ? filterTitle
                    : "Nothing found matching your filter."}
                </Text>
              </Column>
              <Column size={[12, 12, 4]} customStyle={{ mt: 4 }}>
                {renderViewAllButton()}
              </Column>
            </Row>
          </div>
        ) : checkAllFilter() &&
          filteredProjects &&
          filteredProjects.length > 0 ? (
          <div>
            <div className="modelsrow"></div>
            <Row customStyle={{ mb: 3 }}>
              <Column size={[12, 12, 8]} customStyle={{ mt: 4 }}>
                <Text type="h2">Filtered Results</Text>
              </Column>
              <Column size={[12, 12, 4]} customStyle={{ mt: 4 }}>
                {renderViewAllButton()}
              </Column>
            </Row>
          </div>
        ) : filteredProjects && !filteredProjects.length > 0 ? (
          <div>
            <div className="modelsrow"></div>
            <Row customStyle={{ mb: 3 }}>
              <Column size={[12, 12, 8]} customStyle={{ mt: 4 }}>
                <Text type="h2">
                  Nothing found matching your filter.
                </Text>
              </Column>
              <Column size={[12, 12, 4]} customStyle={{ mt: 4 }}>
                {renderViewAllButton()}
              </Column>
            </Row>
          </div>
        ) : null}
        <InfiniteScroll
          loadMore={() => setCardsLimit(cardsLimit + extraCardsAmount)}
          hasMore={cardsLimit < projects.length}
          sx={{ overflow: "visible!important" }}
        >
          <Row customStyle={{ my: 3 }}>
            {groupFilteredProjects && groupFilteredProjects.map((project, index) => {
             return <Column
                customStyle={{
                  mb: theme => theme.spacing.horizontal,
                  ":last-child": { mb: 0 },
                }}
                size={[12, 12, 12]}
                key={index}
              ><Row >
                 {project.type && <Column
                customStyle={{
                  mb: theme => theme.spacing.horizontal,
                  ":last-child": { mb: 0 },
                  marginBottom: 10
                }}
                size={[12, 12, 12]}
              >
                <Text
                type="h2"
                customStyle={{
                  mb: theme => theme.spacing.vertical.sm,
                  marginBottom: 0,
                  marginTop: "1.2rem"
                }}
              >
                {renderLineBreak(project.type)}
              </Text>
              </Column>}
               {project.projects.map((prj, ind) => {
                return  <Column
                customStyle={{
                  mb: theme => theme.spacing.horizontal,
                  ":last-child": { mb: 0 },
                }}
                size={[12, 12, 6]}
                key={prj.id+ind}
              ><ProjectCard
                    background={prj.projectImages[0].fluid.src}
                    link={{
                      to: `/projects/${prj.slug}`,
                      type: "internal",
                    }}
                    text={[prj.projectTitle.projectTitle].concat(
                      prj.projectLocation.projectLocation
                    )}
                    type="tertiary"
                  />
                 </Column>
              })} 
              </Row>
              </Column>
            })}
          </Row>
        </InfiniteScroll>
        {/* <Row>
          <Column size={[12, 12, 12]} customStyle={{ my: 4 }}>
              {legacyButton()}
          </Column>
        </Row> */}
      </Container>
    </Page>
  )
}

export default ProjectGalleryPage

export const query = graphql`
  query ProjectGalleryPageQuery {
    contentfulPage(slug: { eq: "project-gallery" }) {
      ...PageData
    }

    hero: contentfulBasicCard(slug: { eq: "project-gallery-hero" }) {
      ctaTitle
      description {
        description
      }
      title
      ctaDestination {
        ctaDestination
      }
      desktopImage {
        file {
          contentType
          url
        }
      }
    }
    projects:contentfulSequence(slug: { eq: "sequence-projects" }) {
      orderItems {
        ... on ContentfulProject {
          id
          projectImages {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          projectTytpe
          projectType
          slug
        }
      }
    }
    legacyProjects:contentfulSequence(slug: { eq: "sequence-legacy-projects" }) {
      orderItems {
        ... on ContentfulProject {
          id
          projectImages {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          projectTytpe
          projectType
          slug
        }
      }
    }
  }
`
