/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"

import PageLink from "./page-link"
import Text from "./text"

const ProjectCard = ({ background, link,href, text, type, ...restProps }) => {
  const componentType = type || "primary"
  const styleList = {
    height: {
      primary: 385,
      secondary: 665,
      tertiary: [210, 210, 360],
    },
  }

  const renderLineBreak = text => {
    return text && typeof text === "string"
      ? text.split(/\n|\\n/).reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
      : null
  }

  const renderContent = () => {
    return text ? (
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          position: "absolute",
          height: "100%",
          p: [3, 3, 4],
        }}
      >
        {text.map(textContent => {
          return (
            <Text
              customStyle={{
                color: "white",
                fontWeight: 700,
                lineHeight: 1.25,
                fontSize: 17,
                textShadow: "1px 1px 7px rgba(0,0,0,0.6)",
              }}
              key={kebabCase(textContent)}
            >
              {renderLineBreak(textContent)}
            </Text>
          )
        })}
      </div>
    ) : null
  }

  return (
    <div
      sx={{
        ...(background && {
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
        }),
        borderRadius: 5,
        display: "flex",
        height: styleList.height[componentType],
        overflow: "hidden",
      }}
      {...restProps}
    >
      <div
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          ...(link && {
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 100%)",
            },
          }),
        }}
      >
        {link ? (
          href ?<a
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
          href={href}
          target="_self"
        >
          {renderContent()}
        </a>: <PageLink
            customStyle={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
            link={link.to}
            type={link.type}
          >
            {renderContent()}
          </PageLink>
        ) : (
          renderContent()
        )}
      </div>
    </div>
  )
}

ProjectCard.propTypes = {
  background: PropTypes.string,
  link: PropTypes.shape({
    to: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  text: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
}

export default ProjectCard
